import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import GImage from "@components/g-image";
import RichText from "@components/rich-text";

export const sectionsBannerFields = graphql`
  fragment sectionsBannerFields on DirectusData_sections_banner {
    id
    description
    height
    greeting
    background {
      id
      title
      description
      style
      img_style
      object_position
      background_color
      alt
      imageFile {
        id
        ...imageFullWidth
      }
    }
  }
`;

const Banner = ({ data }) => {
  return data ? (
    <div className="relative">
      <div
        className={`${classNames(
          "relative bg-gray-800 dark:bg-gray-700",
          // Fine
          {
            "py-10": data.height === "thin",
          },
          // Normal
          {
            "py-20": data.height === "medium",
          },
          // Large
          {
            "py-32": data.height === "large",
          }
        )}`}
      >
        {data.background ? (
          <React.Fragment>
            <GImage
              data={{
                ...data.background,
              }}
              className="!absolute inset-0 w-full h-full"
            />
            <div className="absolute inset-0 z-10 flex flex-col w-full justify-center items-center">
              {data.description ? (
                <RichText
                  className={`"text-editor c-all:text-white mb-0"}`}
                  content={data.description}
                />
              ) : (
                ""
              )}
            </div>
          </React.Fragment>
        ) : (
          <RichText
            className={`"text-editor c-all:text-white mb-0"}`}
            content={data.description}
          />
        )}
      </div>
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default Banner;
