import React from "react";
import { graphql } from "gatsby";
// import GImage from "@components/g-image";
import classNames from "classnames";
import CustomLink from "@components/custom-link";
import HtmlComponent from "@components/html-component";
import ItemList from "@components/item-list";
import RichText from "@components/rich-text";
import Card from "@components/card";
import AvatarSvg from "@components/avatar-svg";
import DeskSvg from "@components/desk-svg";

export const sectionsFeatureRows = graphql`
  fragment sectionsFeatureRows on DirectusData_sections_feature_rows {
    id
    position
    custom_title {
      title
      tag
    }
    description
    custom_link {
      new_tab
      url
      text
    }
    picture {
      id
      title
      description
      style
      img_style
      object_position
      background_color
      alt
      imageFile {
        id
        ...imageConstrained
      }
    }
  }
`;

const FeatureRows = ({ data }) => {
  return (
    <div className="container max-w-screen-2xl mx-auto flex pt-10 sm:pt-20 pb-2 sm:pb-10">
      {data ? (
        <div className="mx-auto px-4 sm:px-8 max-w-screen-2xl grid grid-cols-1 gap-8 z-10">
          <div
            className={classNames(
              "mx-auto",
              {
                "order-1": data.position === "right",
              },
              {
                "order-2": data.position === "left",
              }
            )}
          >
            <Card
              visible={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.8 },
              }}
              hidden={{
                opacity: 0,
                x: data.position === "right" ? -300 : 300,
              }}
              className="pb-18 h-full bg-white dark:bg-gray-300"
            >
              {data.custom_title ? (
                <HtmlComponent
                  tag={data.custom_title.tag}
                  className="text-2xl mt-7 mb-4 text-left leading-snug text-gray-800 dark:text-gray-800"
                >
                  {data.custom_title.title}
                </HtmlComponent>
              ) : (
                ""
              )}
              {data.description ? (
                <RichText
                  className="text-editor c-p:text-gray-800 c-li:text-gray-800 c-a:text-gray-800 c-blockquote-p:border-gray-700 c-p:mb-2"
                  content={data.description}
                />
              ) : (
                ""
              )}
              {data.custom_link ? (
                <CustomLink
                  className="a-link-blue text-lg"
                  url={data.custom_link.url}
                  new_tab={data.custom_link.new_tab}
                >
                  {data.custom_link.text}
                </CustomLink>
              ) : (
                ""
              )}
              {data.item_list ? (
                <ItemList mb="mb-0" item_list={data.item_list} />
              ) : (
                ""
              )}
            </Card>
          </div>
          <div
            className={classNames(
              "mx-auto w-auto sm:w-128",
              {
                "order-2": data.position === "right",
              },
              {
                "order-1": data.position === "left",
              }
            )}
          >
            <Card
              className="relative h-full"
              p=""
              color=""
              visible={{
                opacity: 1,
                x: 0,
                transition: { duration: 0.8 },
              }}
              hidden={{
                opacity: 0,
                x: data.position === "right" ? 200 : -200,
              }}
            >
              <div className="flex flex-nowrap w-auto h-full sm:h-52">
                {/* <GImage
                  data={data.picture}
                  className="h-44 xl:!absolute xl:inset-0 xl:h-full xl:w-full rounded-3xl"
                /> */}
                <AvatarSvg className="h-full" />
                <DeskSvg className="h-full" />
              </div>
            </Card>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FeatureRows;
