import React from "react";
import { graphql } from "gatsby";
import RichText from "@components/rich-text";
import HtmlComponent from "@components/html-component";
import Card from "@components/card";
import CustomLink from "@components/custom-link";

export const sectionsIntro = graphql`
  fragment sectionsIntro on DirectusData_sections_intro {
    id
    custom_title {
      title
      tag
    }
    intro
    custom_link {
      new_tab
      url
      text
    }
  }
`;

const Intro = ({ data }) => {
  return data ? (
    <div className="relative">
      <div className="flex px-0 sm:px-8 pt-8 lg:pt-10 flex-col w-full justify-start place-items-start max-w-screen-2xl mx-auto">
        <Card
          color=""
          visible={{
            opacity: 1,
            y: 0,
            transition: { duration: 1 },
          }}
          hidden={{ opacity: 0, y: 100 }}
        >
          {data.custom_title ? (
            <HtmlComponent
              tag={data.custom_title.tag}
              className="text-3xl lg:text-5xl max-w-screen-lg w-full font-normal text-gray-800 dark:text-gray-50 mb-5 text-left leading-snug"
            >
              {data.custom_title.title}
            </HtmlComponent>
          ) : (
            ""
          )}
          {data.intro ? (
            <RichText
              className={`text-editor c-p:text-xl dark:c-p:text-gray-200 w-full max-w-screen-lg`}
              content={data.intro}
            />
          ) : (
            ""
          )}
          {data.custom_link ? (
            <CustomLink
              className="a-link-blue text-lg"
              url={data.custom_link.url}
              new_tab={data.custom_link.new_tab}
            >
              {data.custom_link.text}
            </CustomLink>
          ) : (
            ""
          )}
        </Card>
      </div>
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default Intro;
