import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import RichText from "@components/rich-text";

const FMDisclosure = ({ item_list }) => {
  const [expanded, setExpanded] = useState(0);
  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <React.Fragment>
      {item_list && item_list.list
        ? item_list.list.map((item, index) => {
            const isOpen = index === expanded;
            return item ? (
              <div
                id={`disclosureItem${index}`}
                className="w-full pb-4"
                key={index}
              >
                <motion.h4
                  className="flex items-center justify-between text-lg px-4 py-3 mb-0 text-[21px] text-gray-900 dark:text-gray-900 bg-white shadow rounded-sm cursor-pointer"
                  initial={false}
                  // animate={{
                  //   backgroundColor:
                  //     typeof expanded === "number" ? "#FF0088" : "#0055FF",
                  // }}
                  onClick={() => setExpanded(isOpen ? false : index)}
                >
                  <span>{item.titre}</span>
                  <span
                    className={`material-icons-outlined pl-2 font-bold translate-transform duration-700 transform ${
                      isOpen ? "rotate-90" : ""
                    }`}
                  >
                    chevron_right
                  </span>
                </motion.h4>
                <AnimatePresence initial={true}>
                  {isOpen && (
                    <motion.div
                      className=""
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      // onAnimationStart={() => {
                      //   scroll && scroll.stop();
                      // }}
                      // onAnimationComplete={() => {
                      //   scroll && scroll.update();
                      //   scroll && scroll.start();
                      // }}
                      variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 },
                      }}
                      transition={{
                        duration: 0.5,
                        ease: [0.04, 0.62, 0.23, 0.98],
                      }}
                    >
                      <motion.div
                        variants={{
                          collapsed: { scale: 0.8 },
                          open: { scale: 1 },
                        }}
                        transition={{ duration: 0.8 }}
                        className="h-full pt-2 pl-4"
                      >
                        <RichText
                          className="text-editor text-black h-full py-4 px-5 bg-gray-700sky rounded-sm overflow-hidden"
                          content={item.description}
                        />
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ) : (
              ""
            );
          })
        : ""}
    </React.Fragment>
  );
};

FMDisclosure.propTypes = {
  item_list: PropTypes.object.isRequired,
};

export default FMDisclosure;
