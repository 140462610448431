import React from "react";
import { graphql } from "gatsby";
import RichText from "@components/rich-text";

export const articlesFields = graphql`
  fragment articlesFields on DirectusData_articles {
    id
    title
    subtitle
    intro
    description
    custom_link {
      new_tab
      url
      text
    }
    picture {
      id
      title
      description
      style
      img_style
      object_position
      background_color
      alt
      imageFile {
        id
        ...imageConstrained
      }
    }
    background {
      id
      title
      description
      style
      img_style
      object_position
      background_color
      alt
      imageFile {
        id
        ...imageFullWidth
      }
    }
  }
`;

const Articles = ({ data }) => {
  return data ? (
    <div className="relative">
      <div className="flex flex-col w-full justify-center items-center">
        {data.description ? (
          <RichText
            className={`"text-editor mb-0"}`}
            content={data.description}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default Articles;
