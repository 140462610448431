import React from "react";
import PropTypes from "prop-types";

const HtmlComponent = ({ children, className, tag }) => {
  const CustomTag = tag;
  return <CustomTag className={`${className}`}>{children}</CustomTag>;
};

HtmlComponent.propTypes = {
  tag: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default HtmlComponent;
