import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

const Seo = ({ title, description, image, lang, meta, article }) => {
  const { pathname } = useLocation();

  const { directus_system } = useStaticQuery(
    graphql`
      query {
        directus_system {
          settings {
            project_name
            public_note
            project_logo {
              id
              title
              description
              style
              img_style
              object_position
              background_color
              alt
              imageFile {
                id
                publicURL
              }
            }
          }
        }
      }
    `
  );

  const defaultTitle = title || directus_system.settings.project_name;
  const metaDescription = description || directus_system.settings.public_note;

  // console.log("--------");
  // console.log("SEO TITLE", title);
  // console.log("SEO DEFAULT TITLE", defaultTitle);
  // console.log("SEO DESCRIPTION", metaDescription);
  // console.log("SEO PATHNAME", pathname);
  // console.log(
  //   "SEO DEFAULT IMAGE",
  //   directus_system.settings.project_logo.imageFile.publicURL
  // );
  // console.log(
  //   "SEO STATIC IMAGE",
  //   process.env.GATSBY_SITE_URL + "/og-image.jpg"
  // );
  // console.log("SEO IMAGE", image);
  // console.log("--------");

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle}
      link={[
        {
          rel: "canonical",
          href: process.env.GATSBY_SITE_URL + pathname,
        },
        // {
        //   rel: "icon",
        //   type: "image/x-icon",
        //   href: "/logos/favicons/favicon.ico",
        // },
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/logos/favicons/apple-touch-icon.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/logos/favicons/favicon-32x32.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/logos/favicons/favicon-16x16.png",
        },
        // { rel: "manifest", href: "/logos/favicons/site.webmanifest" },
        {
          rel: "mask-icon",
          href: "/logos/favicons/safari-pinned-tab.svg",
          color: "#777777",
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `${
            image && image.publicURL
              ? process.env.GATSBY_SITE_URL + image.publicURL
              : process.env.GATSBY_SITE_URL + "/og-image.jpg"
            // directus_system.settings.project_logo.imageFile.publicURL
          }`,
        },
        {
          property: `fb:app_id`,
          content: `${process.env.GATSBY_FB_APP_ID}`,
        },
        {
          property: `og:url`,
          content: process.env.GATSBY_SITE_URL + pathname,
        },
        {
          property: `og:type`,
          content: `${article ? "article" : "website"}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        // {
        //   name: `twitter:creator`,
        //   content: directus_system.settings.project_name?.author || ``,
        // },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "msapplication-TileColor",
          content: "#ffffff",
        },
        {
          name: "theme-color",
          content: "#ffffff",
        },
        {
          charset: "utf-8",
        },
      ].concat(meta)}
    />
  );
};

Seo.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  article: PropTypes.bool,
};
Seo.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
  article: false,
};

export default Seo;

// const query = graphql`
//   query SEO {
//     site {
//       siteMetadata {
//         defaultTitle: title
//         titleTemplate
//         defaultDescription: description
//         siteUrl: url
//         defaultImage: image
//         twitterUsername
//       }
//     }
//   }
// `;
