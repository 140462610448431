import React from "react";
import PropTypes from "prop-types";

const RichText = ({ content, className }) => {
  return (
    <div
      className={`${className ? className : "text-editor"}`}
      dangerouslySetInnerHTML={{ __html: `${content}` }}
    />
  );
};

RichText.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
    className: PropTypes.string,
  }),
};

export default RichText;
