import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { format } from "date-fns";
import { SelectField, TextField, TextareaField } from "@components/fields";
import useAuth from "@services/provider/use-auth";
import { updateCurrentUser } from "@services/directus/users";
import NotificationTemplate from "@components/notifications";
import useContent from "@services/provider/use-content";
import { useToastControls } from "@services/toasts";
import classNames from "classnames";

export const UserAvailabilityForm = ({ availability }) => {
  const { setCurrentUser, setLoading } = useAuth();
  const { show } = useToastControls();

  const availabilitiesOptions = [
    {
      value: `nc`,
      label: "Non précisé",
    },
    {
      value: `available`,
      label: "Disponible",
    },
    {
      value: `busy`,
      label: "Occupé",
    },
    {
      value: `unavailable`,
      label: "Indisponible",
    },
  ];

  const validationSchema = yup.object({
    availability: yup.string("Choisissez votre disponibilité"),
  });

  const formik = useFormik({
    initialValues: {
      availability: availability,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (values) {
        setLoading(true);
        updateCurrentUser({
          availability: values.availability,
        })
          .then((response) => {
            if (response) {
              // actions.validateForm()
              setCurrentUser({ currentUser: response });
            } else {
              show("toast-warning", <NotificationTemplate code="403" />);
            }
          })
          .catch((_error) => {
            // handleNotification({
            //   code: "USER_EDIT",
            // });
            console.warn(_error.message);
            console.warn(_error.name);
          });
      }
    },
  });

  return (
    <form className="w-full" onSubmit={formik.handleSubmit}>
      <SelectField
        fullWidth
        className={classNames(
          "mt-8 bg-gray-100 dark:bg-gray-700 border-b-2 rounded",
          {
            "border-green-400": formik.values.availability === "available",
            "border-sky-400": formik.values.availability === "busy",
            "border-red-500": formik.values.availability === "unavailable",
            "border-gray-200": formik.values.availability === "nc",
          }
        )}
        label="Disponibilité"
        placeholder="Disponibilité"
        id="edit-availability"
        name="availability"
        options={availabilitiesOptions}
        value={
          formik.values.availability
            ? formik.values.availability
            : availabilitiesOptions[0]
        }
        onChange={(e) => {
          formik.handleChange(e);
          formik.handleSubmit();
        }}
        error={
          formik.touched.availability && Boolean(formik.errors.availability)
        }
        helperText={formik.touched.availability && formik.errors.availability}
      />
    </form>
  );
};

export const UserBirthdayForm = ({ birthday }) => {
  const { show } = useToastControls();
  const { setCurrentUser, setLoading } = useAuth();
  const { setModal, setFixedButton, modalState, fixedButtonState } =
    useContent();

  const validationSchema = yup.object({
    birthday: yup.string("Date d'anniversaire").required("Champ requis"),
  });

  const formik = useFormik({
    initialValues: {
      birthday: birthday,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (values) {
        setLoading(true);
        updateCurrentUser({
          birthday:
            values.birthday && format(new Date(values.birthday), "yyyy-MM-dd"),
        })
          .then((response) => {
            if (response) {
              // actions.validateForm()
              setCurrentUser({ currentUser: response });
              setModal({
                open: false,
                name: null,
              });
              setFixedButton({
                name: null,
                params: null,
                content: <span className="material-icons">done</span>,
              });
            } else {
              show("toast-warning", <NotificationTemplate code="403" />);
            }
          })
          .catch((_error) => {
            // handleNotification({
            //   code: "USER_EDIT",
            // });
            console.warn(_error.message);
            console.warn(_error.name);
          });
      }
    },
  });

  useEffect(() => {
    if (modalState.open === true && fixedButtonState.name !== modalState.name) {
      setFixedButton({
        name: modalState.name,
        params: {
          title: "Valider les modifications",
          onClick: () => formik.handleSubmit(),
          className:
            "shadow h-[72px] rounded-full bg-green-400 hover:bg-green-500 flex justify-center items-center text-white m-0 aspect-square text-4xl",
          type: "button",
        },
        content: <span className="material-icons">done</span>,
      });
    }
  }, [formik, setFixedButton, modalState, fixedButtonState]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        type="date"
        className="mt-8 bg-gray-100 dark:bg-gray-700"
        label="Date"
        placeholder="Date"
        id="edit-birthday"
        name="birthday"
        value={formik.values.birthday ? formik.values.birthday : ""}
        onChange={formik.handleChange}
        error={formik.touched.birthday && Boolean(formik.errors.birthday)}
        helperText={formik.touched.birthday && formik.errors.birthday}
      />
    </form>
  );
};

export const UserDescriptionForm = ({ title, description }) => {
  const { show } = useToastControls();
  const { setCurrentUser, setLoading } = useAuth();
  const { setModal, setFixedButton, modalState, fixedButtonState } =
    useContent();

  const validationSchema = yup.object({
    title: yup.string("Votre activité"),
    description: yup.string("Vous en quelques mots"),
  });

  const formik = useFormik({
    initialValues: {
      title: title ? title : "",
      description: description ? description : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (values) {
        setLoading(true);
        updateCurrentUser({
          title: values.title,
          description: values.description,
        })
          .then((response) => {
            if (response) {
              // actions.validateForm()
              setCurrentUser({ currentUser: response });
              setModal({
                open: false,
                name: null,
              });
              setFixedButton({
                name: null,
                params: null,
                content: <span className="material-icons">done</span>,
              });
            } else {
              show("toast-warning", <NotificationTemplate code="403" />);
            }
          })
          .catch((_error) => {
            // handleNotification({
            //   code: "USER_EDIT",
            // });
            console.warn(_error.message);
            console.warn(_error.name);
          });
      }
    },
  });

  useEffect(() => {
    if (modalState.open === true && fixedButtonState.name !== modalState.name) {
      setFixedButton({
        name: modalState.name,
        params: {
          title: "Valider les modifications",
          onClick: () => formik.handleSubmit(),
          className:
            "shadow h-[72px] rounded-full bg-green-400 hover:bg-green-500 flex justify-center items-center text-white m-0 aspect-square text-4xl",
          type: "button",
        },
        content: <span className="material-icons">done</span>,
      });
    }
  }, [formik, setFixedButton, modalState, fixedButtonState]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        className="mt-8 bg-gray-100 dark:bg-gray-700"
        label="Votre activité"
        placeholder=""
        id="edit-title"
        name="title"
        value={formik.values.title ? formik.values.title : ""}
        onChange={formik.handleChange}
        error={formik.touched.title && Boolean(formik.errors.title)}
        helperText={formik.touched.title && formik.errors.title}
      />
      <TextareaField
        fullWidth
        className="mt-10 bg-gray-100 dark:bg-gray-700"
        label="Vous en quelques mots"
        placeholder="Je suis prof depuis... Je souhaite aider..."
        id="edit-description"
        name="description"
        rows={4}
        value={formik.values.description ? formik.values.description : ""}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
      />
    </form>
  );
};

export const UserEmailForm = ({ email }) => {
  const { show } = useToastControls();
  const { setCurrentUser, setLoading } = useAuth();
  const { setModal, setFixedButton, modalState, fixedButtonState } =
    useContent();

  const validationSchema = yup.object({
    email: yup
      .string("Entrez une adresse email")
      .email("Entrez une adresse email valide")
      .required("Champ requis"),
  });

  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (values) {
        setLoading(true);
        updateCurrentUser({
          email: values.email,
        })
          .then((response) => {
            if (response) {
              // actions.validateForm()
              setCurrentUser({ currentUser: response });
              setModal({
                open: false,
                name: null,
              });
              setFixedButton({
                name: null,
                params: null,
                content: <span className="material-icons">done</span>,
              });
            } else {
              show("toast-warning", <NotificationTemplate code="403" />);
            }
          })
          .catch((_error) => {
            // handleNotification({
            //   code: "USER_EDIT",
            // });
            console.warn(_error.message);
            console.warn(_error.name);
          });
      }
    },
  });

  useEffect(() => {
    if (modalState.open === true && fixedButtonState.name !== modalState.name) {
      setFixedButton({
        name: modalState.name,
        params: {
          title: "Valider les modifications",
          onClick: () => formik.handleSubmit(),
          className:
            "shadow h-[72px] rounded-full bg-green-400 hover:bg-green-500 flex justify-center items-center text-white m-0 aspect-square text-4xl",
          type: "button",
        },
        content: <span className="material-icons">done</span>,
      });
    }
  }, [formik, setFixedButton, modalState, fixedButtonState]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        className="mt-8 bg-gray-100 dark:bg-gray-700"
        label="Adresse email"
        placeholder="Adresse email"
        id="edit-email"
        name="email"
        value={formik.values.email ? formik.values.email : ""}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
    </form>
  );
};

export const UserIdentityForm = ({ first_name, last_name, genre }) => {
  const { show } = useToastControls();
  const { setCurrentUser, setLoading } = useAuth();
  const { setModal, setFixedButton, modalState, fixedButtonState } =
    useContent();

  const genreOptions = [
    {
      value: `nc`,
      label: "Non précisé",
    },
    {
      value: `monsieur`,
      label: "Monsieur",
    },
    {
      value: `madame`,
      label: "Madame",
    },
  ];

  const validationSchema = yup.object({
    genre: yup.string("Sélectionnez votre genre"),
    firstname: yup.string("Entrez votre prénom").required("Champ requis"),
    lastname: yup.string("Entrez votre nom").required("Champ requis"),
  });

  const formik = useFormik({
    initialValues: {
      genre: genre,
      firstname: first_name,
      lastname: last_name,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (values) {
        setLoading(true);
        updateCurrentUser({
          genre: values.genre,
          first_name: values.firstname,
          last_name: values.lastname,
        })
          .then((response) => {
            if (response) {
              // actions.validateForm()
              setCurrentUser({ currentUser: response });
              setModal({
                open: false,
                name: null,
              });
              setFixedButton({
                name: null,
                params: null,
                content: <span className="material-icons">done</span>,
              });
            } else {
              show("toast-warning", <NotificationTemplate code="403" />);
            }
          })
          .catch((_error) => {
            // handleNotification({
            //   code: "USER_EDIT",
            // });
            console.warn(_error.message);
            console.warn(_error.name);
          });
      }
    },
  });

  useEffect(() => {
    if (modalState.open === true && fixedButtonState.name !== modalState.name) {
      setFixedButton({
        name: modalState.name,
        params: {
          title: "Valider les modifications",
          onClick: () => formik.handleSubmit(),
          className:
            "shadow h-[72px] rounded-full bg-green-400 hover:bg-green-500 flex justify-center items-center text-white m-0 aspect-square text-4xl",
          type: "button",
        },
        content: <span className="material-icons">done</span>,
      });
    }
  }, [formik, setFixedButton, modalState, fixedButtonState]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <SelectField
        fullWidth
        className="mt-8 bg-gray-100 dark:bg-gray-700"
        label="Genre"
        placeholder="Genre"
        id="edit-genre"
        name="genre"
        options={genreOptions}
        value={formik.values.genre ? formik.values.genre : genreOptions[0]}
        onChange={formik.handleChange}
        error={formik.touched.genre && Boolean(formik.errors.genre)}
        helperText={formik.touched.genre && formik.errors.genre}
      />
      <TextField
        fullWidth
        className="mt-8 bg-gray-100 dark:bg-gray-700"
        label="Prénom"
        placeholder="Prénom"
        id="edit-firstname"
        name="firstname"
        value={formik.values.firstname ? formik.values.firstname : ""}
        onChange={formik.handleChange}
        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
        helperText={formik.touched.firstname && formik.errors.firstname}
      />
      <TextField
        fullWidth
        className="mt-8 bg-gray-100 dark:bg-gray-700"
        label="Nom"
        placeholder="Nom"
        id="edit-lastname"
        name="lastname"
        value={formik.values.lastname ? formik.values.lastname : ""}
        onChange={formik.handleChange}
        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
        helperText={formik.touched.lastname && formik.errors.lastname}
      />
    </form>
  );
};

export const UserLocationForm = ({ location }) => {
  const { show } = useToastControls();
  const { setCurrentUser, setLoading } = useAuth();
  const { setModal, setFixedButton, modalState, fixedButtonState } =
    useContent();

  const validationSchema = yup.object({
    location: yup.string("Votre ville et pays").required("Champ requis"),
  });

  const formik = useFormik({
    initialValues: {
      location: location,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (values) {
        setLoading(true);
        updateCurrentUser({
          location: values.location,
        })
          .then((response) => {
            if (response) {
              // actions.validateForm()
              setCurrentUser({ currentUser: response });
              setModal({
                open: false,
                name: null,
              });
              setFixedButton({
                name: null,
                params: null,
                content: <span className="material-icons">done</span>,
              });
            } else {
              show("toast-warning", <NotificationTemplate code="403" />);
            }
          })
          .catch((_error) => {
            // handleNotification({
            //   code: "USER_EDIT",
            // });
            console.warn(_error.message);
            console.warn(_error.name);
          });
      }
    },
  });

  useEffect(() => {
    if (modalState.open === true && fixedButtonState.name !== modalState.name) {
      setFixedButton({
        name: modalState.name,
        params: {
          title: "Valider les modifications",
          onClick: () => formik.handleSubmit(),
          className:
            "shadow h-[72px] rounded-full bg-green-400 hover:bg-green-500 flex justify-center items-center text-white m-0 aspect-square text-4xl",
          type: "button",
        },
        content: <span className="material-icons">done</span>,
      });
    }
  }, [formik, setFixedButton, modalState, fixedButtonState]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        className="mt-8 bg-gray-100 dark:bg-gray-700"
        label="Lieu"
        placeholder="Lieu"
        id="edit-location"
        name="location"
        value={formik.values.location ? formik.values.location : ""}
        onChange={formik.handleChange}
        error={formik.touched.location && Boolean(formik.errors.location)}
        helperText={formik.touched.location && formik.errors.location}
      />
    </form>
  );
};

export const UserPasswordForm = () => {
  const { show } = useToastControls();
  const { setCurrentUser, setLoading } = useAuth();
  const { setModal, setFixedButton, modalState, fixedButtonState } =
    useContent();

  const validationSchema = yup.object({
    password: yup
      .string("Entrez un mot de passe")
      .min(8, "Minimum 8 caractères")
      .required("Veuillez saisir un mot de passe"),
    password_confirm: yup
      .string("Entrez un mot de passe")
      .min(8, "Minimum 8 caractères")
      .required("Veuillez saisir un mot de passe")
      .oneOf(
        [yup.ref("password"), null],
        "Le mot de passe doit être identique."
      ),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (values) {
        setLoading(true);
        updateCurrentUser({
          password: values.password,
        })
          .then((response) => {
            if (response) {
              // actions.validateForm()
              setCurrentUser({ currentUser: response });
              setModal({
                open: false,
                name: null,
              });
              setFixedButton({
                name: null,
                params: null,
                content: <span className="material-icons">done</span>,
              });
            } else {
              show("toast-warning", <NotificationTemplate code="403" />);
            }
          })
          .catch((_error) => {
            // handleNotification({
            //   code: "USER_EDIT",
            // });
            console.warn(_error.message);
            console.warn(_error.name);
          });
      }
    },
  });

  useEffect(() => {
    if (modalState.open === true && fixedButtonState.name !== modalState.name) {
      setFixedButton({
        name: modalState.name,
        params: {
          title: "Valider les modifications",
          onClick: () => formik.handleSubmit(),
          className:
            "shadow h-[72px] rounded-full bg-green-400 hover:bg-green-500 flex justify-center items-center text-white m-0 aspect-square text-4xl",
          type: "button",
        },
        content: <span className="material-icons">done</span>,
      });
    }
  }, [formik, setFixedButton, modalState, fixedButtonState]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        className="mt-8 bg-gray-100 dark:bg-gray-700"
        label="Nouveau mot de passe"
        placeholder="Nouveau mot de passe"
        id="edit-password"
        name="password"
        type="password"
        value={formik.values.password ? formik.values.password : ""}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <TextField
        fullWidth
        className="mt-8 bg-gray-100 dark:bg-gray-700"
        label="Confirmation"
        placeholder="Confirmation du nouveau mot de passe"
        id="edit-password_confirm"
        name="password_confirm"
        type="password"
        value={
          formik.values.password_confirm ? formik.values.password_confirm : ""
        }
        onChange={formik.handleChange}
        error={
          formik.touched.password_confirm &&
          Boolean(formik.errors.password_confirm)
        }
        helperText={
          formik.touched.password_confirm && formik.errors.password_confirm
        }
      />
    </form>
  );
};

export const UserPhoneForm = ({ phone }) => {
  const { show } = useToastControls();
  const { setCurrentUser, setLoading } = useAuth();
  const { setModal, setFixedButton, modalState, fixedButtonState } =
    useContent();

  const validationSchema = yup.object({
    phone: yup.string("Entrez votre numéro").required("Champ requis"),
  });

  const formik = useFormik({
    initialValues: {
      phone: phone,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      if (values) {
        setLoading(true);
        updateCurrentUser({
          phone: values.phone,
        })
          .then((response) => {
            if (response) {
              // actions.validateForm()
              setCurrentUser({ currentUser: response });
              setModal({
                open: false,
                name: null,
              });
              setFixedButton({
                name: null,
                params: null,
                content: <span className="material-icons">done</span>,
              });
            } else {
              show("toast-warning", <NotificationTemplate code="403" />);
            }
          })
          .catch((_error) => {
            // handleNotification({
            //   code: "USER_EDIT",
            // });
            console.warn(_error.message);
            console.warn(_error.name);
          });
      }
    },
  });

  useEffect(() => {
    if (modalState.open === true && fixedButtonState.name !== modalState.name) {
      setFixedButton({
        name: modalState.name,
        params: {
          title: "Valider les modifications",
          onClick: () => formik.handleSubmit(),
          className:
            "shadow h-[72px] rounded-full bg-green-400 hover:bg-green-500 flex justify-center items-center text-white m-0 aspect-square text-4xl",
          type: "button",
        },
        content: <span className="material-icons">done</span>,
      });
    }
  }, [formik, setFixedButton, modalState, fixedButtonState]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        className="mt-8 bg-gray-100 dark:bg-gray-700"
        label="Numéro"
        placeholder="Numéro"
        id="edit-phone"
        name="phone"
        type="tel"
        value={formik.values.phone ? formik.values.phone : ""}
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
      />
    </form>
  );
};
