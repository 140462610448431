import React from "react";
import { graphql } from "gatsby";
import RichText from "@components/rich-text";
import HtmlComponent from "@components/html-component";
import Card from "@components/card";
import GImage from "@components/g-image";

export const sectionsFeatureColumns = graphql`
  fragment sectionsFeatureColumns on DirectusData_sections_feature_columns {
    id
    custom_title {
      title
      tag
    }
    background {
      id
      title
      description
      style
      img_style
      object_position
      background_color
      alt
      imageFile {
        id
        ...imageFullWidth
      }
    }
    item_list {
      title
      list
    }
  }
`;

const FeatureColumns = ({ data }) => {
  return (
    <div
      className="relative pt-12 sm:pt-24 pb-20 sm:pb-32 bg-gradient-to-tl from-sky-300 to-sky-200 via-gray-300"
      // style={{
      //   background:
      //     "linear-gradient(160deg, rgba(120,197,217,1) 0%, rgba(242,195,89,1) 33%, rgba(241,156,104,1) 66%, rgba(164,139,159,1) 100%)",
      // }}
    >
      {data.background && (
        <GImage
          data={data.background}
          className="!absolute inset-0 w-full h-full"
        />
      )}
      <div className="relative max-w-screen-2xl mx-auto px-4 sm:px-8">
        <Card
          color=""
          p="pb-5 px-2 sm:px-10"
          className="w-full"
          visible={{
            opacity: 1,
            scale: 1,
            transition: { duration: 1 },
          }}
          hidden={{ opacity: 0, scale: 0.8 }}
        >
          {data.custom_title ? (
            <HtmlComponent
              tag={data.custom_title.tag}
              className="lg:text-3xl text-gray-800 dark:text-gray-800 font-normal mb-5 max-w-2xl leading-normal"
            >
              {data.custom_title.title}
            </HtmlComponent>
          ) : (
            ""
          )}
        </Card>
        {/* <Card
        p="sm:px-9 sm:py-9"
        color="sm:bg-white sm:dark:bg-gray-950"
        className="pt-9 pb-18"
      > */}
        {/* {data.item_list.title && (
          <HtmlComponent
            tag="h3"
            className="text-[21px] text-sky-500 dark:text-gray-800 mb-9"
          >
            {data.item_list.title}
          </HtmlComponent>
        )} */}
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
          {data.item_list &&
            data.item_list.list.map((item, index) => (
              <Card
                visible={{
                  opacity: 1,
                  scale: 1,
                  transition: { duration: 1 },
                }}
                hidden={{ opacity: 0, scale: 0.8 }}
                color="bg-white bg-opacity-50 backdrop-blur"
                className="relative text-center rounded-xl"
                key={index}
              >
                <div className="text-left">
                  {/* <div className="flex justify-start items-center mb-4"> */}
                  <span className="material-icons-outlined absolute right-0 top-2 text-gray-800 dark:text-gray-800 text-3xl pr-4">
                    {item.icone}
                  </span>
                  {item.titre ? (
                    <h4 className="text-2xl text-gray-800 dark:text-gray-800 mt-7 mb-4 text-left leading-snug font-normal">
                      {item.titre}
                    </h4>
                  ) : (
                    ""
                  )}
                  {/* </div> */}
                  {item.description ? (
                    <RichText
                      className="text-editor dark:c-p:text-gray-800 text-left"
                      content={item.description}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Card>
            ))}
        </div>
        {/* </Card> */}
      </div>
    </div>
  );
};

export default FeatureColumns;
