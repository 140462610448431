import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import axios from "axios";
// import { renderEmail } from "react-html-email";
import ItemList from "@components/item-list";
// import EmailTemplate from "@templates/emailTemplate";
import Transition from "@services/transition";
import useContent from "@services/provider/use-content";
import GImage from "@components/g-image";
import HtmlComponent from "@components/html-component";
import Card from "@components/card";

export const sectionsContactForm = graphql`
  fragment sectionsContactForm on DirectusData_sections_contact_form {
    id
    custom_title {
      title
      tag
    }
    item_list {
      title
      list
    }
    background {
      id
      title
      description
      style
      img_style
      object_position
      background_color
      alt
      imageFile {
        id
        ...imageFullWidth
      }
    }
  }
`;

const params = {
  defaultSubject: "information",
  titleMsgSent: "Message envoyé",
  textMsgSent: `Votre message a bien été envoyé ! ${process.env.GATSBY_SITE_TITLE} vous contactera dès que possible.`,
  titleMsgError: "Erreur lors de l'envoi du message",
  textMsgError: `Un problème est survenu lors de l'envoi de votre message et il n'a pu être envoyé. En attendant la résolution du problème, vous pouvez nous contacter par mail à ${process.env.GATSBY_SITE_EMAIL}`,
  textButtonSend: "Envoyer",
  textButtonLoad: "Chargement...",
};

const emailRegexp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const path = process.env.GATSBY_API_PATH;
const initialState = {
  required_to_send: false,
  fullname: "",
  subject: "",
  phone: "",
  email: "",
  message: "",
};

const initialErrors = {
  subject: false,
  fullname: false,
  phone: false,
  email: false,
  message: false,
  security_response: false,
};

const securityOptions = [
  {
    value: "e1QDUWmOCU",
    label: <span className="material-icons-outlined text-4xl">menu_book</span>,
  },
  {
    value: "3d3uGmTuJR",
    label: (
      <span className="material-icons-outlined text-4xl">emoji_objects</span>
    ),
  },
  {
    value: "3ixUulBWl2",
    label: (
      <span className="material-icons-outlined text-4xl">star_border</span>
    ),
  },
  {
    value: "EgA66ewSx7",
    label: (
      <span className="material-icons-outlined text-4xl">architecture</span>
    ),
  },
];

let encryptedTokenTmp = "";

const ContactForm = ({ data }) => {
  const [input, setInput] = useState(initialState);
  const [responseErrors, setResponseErrors] = useState(initialErrors);
  const [encryptedToken, setEncryptedToken] = useState(null);
  const [sendDisabled, setSendDisabled] = useState(false);
  const { setModal, setFixedButton } = useContent();

  const subjectOptions = [
    {
      disabled: true,
      default: !params.defaultSubject ? true : false,
      value: `${process.env.GATSBY_SITE_URI} - Nouvelle demande de contact`,
      label: "Choisissez un objet",
    },
    {
      default: params.defaultSubject === "information" ? true : false,
      value: `${process.env.GATSBY_SITE_URI} - Nouvelle demande d'information`,
      label: "Demande d'information",
    },
    {
      default: params.defaultSubject === "inscription" ? true : false,
      value: `${process.env.GATSBY_SITE_URI} - Nouvelle demande de devis`,
      label: "Devis",
    },
    {
      default: params.defaultSubject === "autre" ? true : false,
      value: `${process.env.GATSBY_SITE_URI} - Autre`,
      label: "Autre",
    },
  ];
  const defaultValue = subjectOptions.filter((obj) => obj.default === true);

  const handleInputChange = (e) => {
    if (!e) {
      e.target = {
        type: "select",
        value: defaultValue[0].value,
        name: "subject",
      };
    }
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setInput({
      ...input,
      [name]: value,
    });
  };

  useEffect(() => {
    encryptedTokenTmp = encryptedToken;
  }, [encryptedToken]);

  const handleSubmitSecurity = (e) => {
    e.preventDefault();
    const tokenResponse = e.target.value;
    setModal({
      open: false,
      name: "contact-modal",
      params: {
        handleClose: () => {
          setModal({
            open: false,
            name: null,
          });
          setFixedButton({
            name: null,
            params: null,
          });
        },
      },
      content: (
        <div className="pb-4">
          <h3 className="my-4">
            <span className="material-icons-outlined pr-2 text-inherit text-2xl">
              send
            </span>
            Question de sécurité
          </h3>
          <hr className="w-full m-0 p-0 mb-5" />

          <div className="grid grid-cols-1 gap-2">
            <span className="cursor-pointer w-full block p-2 border-transparent border-2 mx-auto">
              <span className="mx-auto animate animate-spin material-icons-outlined">
                sync
              </span>{" "}
              Chargement en cours...
            </span>
          </div>
        </div>
      ),
    });
    handleSubmit(e, tokenResponse);
  };

  const handleSubmit = async (e, securityResponse) => {
    e.preventDefault();
    // console.log(input);
    var link = "";
    setSendDisabled(true);
    // const messageHtml = renderEmail(
    //   <EmailTemplate
    //     subject={input.subject}
    //     email={input.email}
    //     phone={input.phone}
    //     link={window.location.href}
    //     name={input.fullname}
    //   >
    //     {input.message}
    //   </EmailTemplate>
    // );
    if (
      // input.fullname &&
      // input.fullname.replace(/\s/g, "").length > 0 &&
      input.email &&
      // input.phone &&
      // input.phone.replace(/\s/g, "").length > 0 &&
      input.rgpd &&
      emailRegexp.test(input.email) &&
      input.message &&
      input.message.replace(/\s/g, "").length > 0
    ) {
      if (window !== "undefined" && window.location.href) {
        link = window.location.href;
      }
      axios
        .post(path, {
          subject: input.subject,
          name: input.fullname,
          email: input.email,
          phone: input.phone,
          message: input.message,
          link: link,
          // messageHtml: messageHtml,
          rgpd: input.rgpd,
          required_to_send: input.required_to_send,
          security_response: securityResponse,
          encrypted_token: encryptedTokenTmp,
        })
        .then((response) => {
          if (process.env.GATSBY_DEBUG === "1") {
            console.log("FRONT_INPUT:", input);
            console.log("FRONT_RESPONSE:", response);
          }
          if (response.data.msg === "success") {
            setInput({ ...initialState, rgpd: input.rgpd });
            setResponseErrors(initialErrors);
            setModal({
              open: true,
              name: "contact-modal",
              params: {
                handleClose: () => {
                  setModal({
                    open: false,
                    name: null,
                  });
                  setFixedButton({
                    name: null,
                    params: null,
                  });
                },
              },
              content: (
                <div className="pb-4">
                  <h3 className="my-4">
                    <span className="material-icons-outlined pr-2 text-inherit text-2xl">
                      send
                    </span>
                    {params.titleMsgSent}
                  </h3>
                  <hr className="w-full m-0 p-0 mb-5" />
                  <p>{params.textMsgSent}</p>
                </div>
              ),
            });
          } else if (
            response.data.securityQuestion &&
            response.data.securityQuestion.length > 0 &&
            response.data.securityToken
          ) {
            setTimeout(() => {
              setModal({
                open: true,
                name: "contact-modal",
                params: {
                  handleClose: () => {
                    setModal({
                      open: false,
                      name: null,
                    });
                    setFixedButton({
                      name: null,
                      params: null,
                    });
                  },
                },
                content: (
                  <div className="pb-4">
                    <h3 className="my-4">
                      <span className="material-icons-outlined pr-2 text-inherit text-2xl">
                        send
                      </span>
                      {response.data.securityQuestion}
                    </h3>
                    <hr className="w-full m-0 p-0 mb-5" />
                    <form method="post">
                      <div className="grid grid-cols-4 gap-2">
                        {securityOptions.map((obj, index) => (
                          <Transition
                            key={index}
                            show={!sendDisabled}
                            appear={true}
                            className=""
                            enter={`transition ease-in-out duration-${
                              index * 500
                            } transform`}
                            enterStart="opacity-0 -translate-y-8"
                            enterEnd="opacity-100 translate-y-0"
                          >
                            <input
                              onClick={handleSubmitSecurity}
                              className="hidden"
                              id={`security_${index}`}
                              name="security"
                              type="radio"
                              defaultValue={obj.value}
                            />
                            <label
                              className="cursor-pointer w-full p-2 border-gray-500 border-2 text-center flex justify-center h-full"
                              htmlFor={`security_${index}`}
                            >
                              {obj.label}
                            </label>
                          </Transition>
                        ))}
                      </div>
                    </form>
                  </div>
                ),
              });
            }, 500);

            setResponseErrors(initialErrors);
            setEncryptedToken(response.data.securityToken);
          } else {
            setResponseErrors(response.data.errors);
          }
          setTimeout(() => {
            setSendDisabled(false);
          }, 500);
        })
        .catch((_error) => {
          if (process.env.GATSBY_DEBUG === "1") {
            console.warn("FRONT_RESPONSE_ERROR:", _error);
          }
          setTimeout(() => {
            setSendDisabled(false);
          }, 500);
          setResponseErrors({
            fullname: false,
            email: true,
            phone: false,
            message: true,
          });

          setModal({
            open: true,
            name: "contact-modal",
            params: {
              handleClose: () => {
                setModal({
                  open: false,
                  name: null,
                });
                setFixedButton({
                  name: null,
                  params: null,
                });
              },
            },
            content: (
              <div className="">
                <h4 className="my-4">
                  <span className="material-icons-outlined pr-2 text-inherit text-2xl">
                    send
                  </span>
                  {params.titleMsgError}
                </h4>
                <hr className="w-full m-0 p-0 mb-5" />
                <p>{params.textMsgError}</p>
              </div>
            ),
          });
        });
    } else {
      setResponseErrors({
        // fullname:
        //   !input.fullname || input.fullname.replace(/\s/g, "").length === 0,
        email: !input.email || !emailRegexp.test(input.email),
        // phone: !input.phone || !input.phone.replace(/\s/g, "").length === 0,
        rgpd: !input.rgpd || input.rgpd.length === 0,
        message:
          !input.message || input.message.replace(/\s/g, "").length === 0,
      });
      setTimeout(() => {
        setSendDisabled(false);
      }, 500);
    }
  };

  return (
    <div id="contact" className="relative">
      <div className="relative max-w-screen-xl mx-auto pb-32 pt-4 sm:pt-10">
        {data.custom_title ? (
          <Card className="pb-5" color="">
            <HtmlComponent tag={data.custom_title.tag} className="mb-5">
              {data.custom_title.title}
            </HtmlComponent>
          </Card>
        ) : (
          ""
        )}
        <div
          className={`grid grid-cols-1 z-10 gap-8 ${
            data.item_list ? "xl:grid-cols-2" : ""
          }`}
        >
          <div className="order-2 xl:order-1 mx-auto">
            <div className="w-full rounded-xl bg-white dark:bg-gray-800 pt-9 pb-14 px-6 sm:px-10">
              {/* Contact form */}
              <form id="contact-wrap" className="mx-auto">
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className={`block text-sm mb-1 text-gray-500 dark:text-gray-300 ${
                        responseErrors && responseErrors.fullname
                          ? "font-bold"
                          : "font-bold"
                      }`}
                      htmlFor="full-name"
                    >
                      Nom{" "}
                      <span
                        className={`${
                          responseErrors && responseErrors.fullname
                            ? "text-red-500"
                            : "text-blue-500"
                        }`}
                      ></span>
                    </label>
                    <input
                      type="text"
                      name="fullname"
                      id="full-name"
                      placeholder="Entrez votre nom"
                      value={input.fullname}
                      onChange={handleInputChange}
                      className={`form-input font-semibold rounded-xl ring-2 ring-inset w-full ${
                        responseErrors && responseErrors.fullname
                          ? "ring-red-300"
                          : "ring-gray-300"
                      }`}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className={`block text-sm mb-1 text-gray-500 dark:text-gray-300 ${
                        responseErrors && responseErrors.email
                          ? "font-bold"
                          : "font-bold"
                      }`}
                      htmlFor="email"
                    >
                      Adresse email{" "}
                      <span
                        className={`${
                          responseErrors && responseErrors.email
                            ? "text-red-500"
                            : "text-blue-500"
                        }`}
                      >
                        *
                      </span>
                    </label>
                    <input
                      required
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Entrez votre email"
                      value={input.email}
                      onChange={handleInputChange}
                      className={`form-input font-semibold rounded-xl ring-2 ring-inset w-full ${
                        responseErrors && responseErrors.email
                          ? "ring-red-300"
                          : "ring-gray-300"
                      }`}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <label
                      className={`block text-sm mb-1 text-gray-500 dark:text-gray-300 ${
                        responseErrors && responseErrors.phone
                          ? "font-bold"
                          : "font-bold"
                      }`}
                      htmlFor="phone"
                    >
                      Numéro de téléphone{" "}
                      <span
                        className={`${
                          responseErrors && responseErrors.phone
                            ? "text-red-500"
                            : "text-blue-500"
                        }`}
                      ></span>
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Entrez votre numéro de téléphone"
                      value={input.phone}
                      onChange={handleInputChange}
                      className={`form-input font-semibold rounded-xl w-full ring-2 ring-inset ${
                        responseErrors && responseErrors.phone
                          ? "ring-red-300"
                          : "ring-gray-300"
                      }`}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-5">
                  <div className="w-full px-3">
                    <div className="flex justify-between items-center mb-1">
                      <label
                        className={`block text-sm text-gray-500 dark:text-gray-300 ${
                          responseErrors && responseErrors.message
                            ? "font-bold"
                            : "font-bold"
                        }`}
                        htmlFor="message"
                      >
                        Message{" "}
                        <span
                          className={`text-sm ${
                            responseErrors && responseErrors.message
                              ? "text-red-500"
                              : "text-blue-500"
                          }`}
                        >
                          *
                        </span>
                      </label>
                    </div>
                    <textarea
                      required
                      id="message"
                      name="message"
                      rows="5"
                      placeholder="Saisissez votre message"
                      value={input.message}
                      onChange={handleInputChange}
                      className={`font-semibold rounded-xl ring-2 ring-inset ${
                        responseErrors && responseErrors.message
                          ? "ring-red-300"
                          : "ring-gray-300"
                      } form-textarea w-full resize-y`}
                    ></textarea>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name="required_to_send"
                  id="required_to_send"
                  className={`form-checkbox hidden`}
                  onChange={handleInputChange}
                />
                <div>
                  <label
                    htmlFor="rgpd"
                    className={`${
                      responseErrors && responseErrors.rgpd
                        ? "text-red-500"
                        : "text-gray-500 dark:text-gray-300"
                    } flex items-start text-xs`}
                  >
                    <input
                      required
                      type="checkbox"
                      name="rgpd"
                      id="rgpd"
                      value={input.rgpd}
                      className={`border-0 ring-2 ring-inset rounded mt-1 shadow-none form-checkbox ${
                        responseErrors && responseErrors.rgpd
                          ? "ring-red-400"
                          : "ring-gray-400"
                      }`}
                      defaultChecked={false}
                      onChange={handleInputChange}
                    />
                    <span
                      className={`ml-2 text-gray-500 dark:text-gray-400 ${
                        responseErrors && responseErrors.rgpd
                          ? "font-bold"
                          : "font-bold"
                      }`}
                    >
                      En soumettant ce formulaire, j'accepte que les
                      informations saisies ci-dessus soient utilisées pour
                      permettre de me recontacter, et dans le cadre de la
                      relation commerciale qui découle de cette demande de
                      devis.&nbsp;
                      <span
                        className={`text-xs ${
                          responseErrors && responseErrors.rgpd
                            ? "text-red-500"
                            : "text-blue-500"
                        }`}
                      >
                        *
                      </span>
                    </span>
                  </label>
                </div>

                {responseErrors.email ||
                responseErrors.message ||
                responseErrors.rgpd ? (
                  <div className="bg-gray-700 px-4 py-4 rounded-xl mt-6">
                    <p className="text-sm text-gray-100">
                      Vérifiez que les champs accompagnés d'une astérisque{" "}
                      <span className="text-white">*</span> soient correctements
                      remplis
                    </p>
                  </div>
                ) : (
                  ""
                )}

                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button
                      type="submit"
                      disabled={sendDisabled}
                      onClick={handleSubmit}
                      className="btn flex w-full"
                    >
                      <span
                        className={`material-icons-outlined mr-2 text-base transform dark:text-gray-600 text-gray-600`}
                      >
                        horizontal_rule
                      </span>
                      <span>
                        {sendDisabled
                          ? params.textButtonLoad
                          : params.textButtonSend}
                      </span>
                      <span
                        className={`material-icons-outlined mr-2 text-base transform dark:text-gray-600 text-gray-600`}
                      >
                        horizontal_rule
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {data.item_list && (
            <div className="relative h-full w-full order-1 xl:order-2 mx-auto rounded-xl flex justify-center items-start">
              <GImage
                data={data.background}
                className="!absolute inset-0 h-full w-full"
              />
              <div className="relative w-full sm:w-auto rounded-xl bg-white bg-opacity-80 dark:bg-opacity-80 backdrop-blur dark:bg-gray-800 mx-10 my-16 pt-9 pb-7 px-4 sm:px-10">
                {data.item_list.title ? (
                  <h3 className="text-[21px] text-gray-800 mb-4">
                    {data.item_list.title}
                  </h3>
                ) : (
                  ""
                )}
                <ItemList item_list={data.item_list} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
