import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { motion } from "framer-motion";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import GImage from "@components/g-image";
import HtmlComponent from "@components/html-component";
import CustomLink from "@components/custom-link";
import Card from "@components/card";

export const sectionsHero = graphql`
  fragment sectionsHero on DirectusData_sections_hero {
    id
    custom_title {
      title
      tag
    }
    color
    custom_link {
      new_tab
      url
      text
    }
    background {
      id
      title
      description
      style
      img_style
      object_position
      background_color
      alt
      imageFile {
        id
        ...imageFullWidth
      }
    }
    picture {
      id
      title
      description
      style
      img_style
      object_position
      background_color
      alt
      imageFile {
        id
        ...imageConstrained
      }
    }
  }
`;

const View1 = ({ data }) => {
  return (
    <div className="relative font-display w-full mx-auto flex justify-center items-start min-h-80vh lg:min-w-screen h-screen lg:h-screen bg-gradient-to-tr from-sky-200 via-sky-100 to-sky-50 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600">
      <div className="relative flex h-full w-full rounded-inherit">
        {data.background && (
          <GImage
            data={data.background}
            className="!absolute inset-0 w-full h-full"
          />
        )}
        <div className="relative overflow-hidden flex flex-col lg:flex-row gap-8 lg:gap-12 items-center justify-evenly hero-wrapper mx-auto w-full h-full">
          <div className="pt-28 pb-10 lg:py-0 h-auto lg:h-full w-full lg:w-auto flex flex-wrap items-center justify-center bg-gray-50 dark:bg-gray-900 lg:shadow-lg shrink-0">
            <div className="w-full max-w-xl px-10 py-4">
              <div className="flex mb-4 flex-col justify-start items-center w-full">
                {data.custom_title ? (
                  <HtmlComponent
                    tag={data.custom_title.tag}
                    className="text-left self-start text-shadow"
                  >
                    {data.custom_title.title}
                  </HtmlComponent>
                ) : (
                  ""
                )}

                <div className="self-start mt-6 text-editor">
                  <p>
                    Échangez dès maintenant avec la communauté T&T et résolvez
                    tous vos problèmes efficacement.
                  </p>
                </div>
              </div>

              {data.custom_link ? (
                <div className="w-full text-left">
                  <CustomLink
                    className="a-link"
                    title={data.custom_link.text}
                    url={data.custom_link.url}
                    new_tab={data.custom_link.new_tab}
                  >
                    {data.custom_link.text}
                  </CustomLink>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <AnchorLink
            to="/#sections_intro19"
            title="Défiler vers le bas"
            className="absolute bottom-0 left-0 right-0 py-8 px-10 w-full inline-flex justify-center items-end text-center"
            stripHash
          >
            <span className="animate animate-float material-icons-outlined text-white dark:text-white text-6xl">
              expand_more
            </span>
          </AnchorLink> */}
          </div>
          <div className="h-full w-full flex justify-center items-center">
            <GImage
              className="max-w-md lg:max-w-xl"
              objectFit="contain"
              data={data.picture}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const View2 = ({ data }) => {
  // const { spring } = useContext(FramerMotionScrollContext);
  const [screenHeight, setScreenHeight] = useState(0);
  // const controls = useAnimation();
  // const { scrollY } = useScroll(); // measures how many pixels user has scrolled vertically
  // const input = [0, Math.abs(screenHeight) * -1];
  // const output = [0, Math.abs(screenHeight / 6) * -1];
  // const transformY = useTransform(spring, input, output);
  const transformY = "800px";

  useEffect(() => {
    if (window !== "undefined" && window.innerHeight > 0) {
      setScreenHeight(window.innerHeight);
    }
  }, [setScreenHeight]);

  return data && transformY && screenHeight ? (
    <div className="relative mx-auto flex w-full justify-center items-start min-h-80vh xs:h-screen">
      <motion.div
        // style={{ willChange: "transform" }}
        // animate={controls}
        // className="xs:fixed xs:top-0 xs:bottom-0 w-full"
        className="w-full h-full overflow-hidden"
      >
        <motion.div
          className="relative h-full rounded-inherit"
          // style={{
          //   backgroundColor: data.color,
          //   willChange: "transform",
          //   y: transformY,
          // }}
          // transformTemplate={({ y }) => {
          //   return `translateY(${Math.abs(parseFloat(y))}px)`;
          // }}
          // transformTemplate={({ scrollY }) => `translateY(${scrollY}px)`}
        >
          {data.background && (
            <GImage
              loading="eager"
              data={data.background}
              className="!absolute inset-0 w-full h-full"
            />
          )}
          <div className="relative hero-wrapper h-full">
            <div className="flex flex-col w-full h-screen xs:h-full justify-center items-center max-w-screen-2xl mx-auto xs:px-8">
              <Card
                visible={{
                  opacity: 1,
                  y: 0,
                  scale: 1,
                  transition: { duration: 2 },
                }}
                hidden={{ opacity: 0, y: 20, scale: 0.6 }}
                className="z-10"
                color=""
              >
                {data.custom_title ? (
                  <HtmlComponent
                    tag={data.custom_title.tag}
                    className="select-none text-3xl5 sm:text-4xl md:text-5xl lg:text-6xl font-bold sm:font-extrabold text-white dark:text-white mb-5 text-shadow-xl dark:text-shadow-xl max-w-2xl text-center leading-snug"
                  >
                    {data.custom_title.title}
                  </HtmlComponent>
                ) : (
                  ""
                )}
                {data.custom_link ? (
                  <Card
                    visible={{
                      opacity: 1,
                      y: 0,
                      transition: { duration: 1, delay: 2 },
                    }}
                    rounded=""
                    hidden={{ opacity: 0, y: 30 }}
                    className="absolute left-0 right-0 bg-white dark:bg-black bg-opacity-20 dark:bg-opacity-20 backdrop-blur pt-4 pb-4 w-full text-center"
                    color=""
                    p=""
                  >
                    <CustomLink
                      className="a-link text-white dark:text-white text-shadow-xl dark:text-shadow-xl"
                      url={data.custom_link.url}
                      new_tab={data.custom_link.new_tab}
                    >
                      {data.custom_link.text}
                    </CustomLink>
                  </Card>
                ) : (
                  ""
                )}
              </Card>
              <AnchorLink
                to="/#sections_intro19"
                title="Défiler vers le bas"
                className="absolute bottom-0 left-0 right-0 py-8 px-6 sm:px-10 w-full inline-flex justify-center items-end text-center"
                stripHash
              >
                <span className="animate animate-float material-icons-outlined text-white dark:text-white text-6xl">
                  expand_more
                </span>
              </AnchorLink>
            </div>
          </div>
        </motion.div>

        {/*<div className="max-w-screen-2xl mx-auto pt-20 grid px-4 sm:px-8 gap-8 grid-cols-1 xl:grid-cols-2 justify-center items-center z-10">
      <div className="order-2 xl:order-1 xl:py-6 mx-auto">

         <div className="w-auto rounded-3xl bg-white dark:bg-gray-800 py-9 px-6 sm:px-10">
          {data.title ? (
            <HtmlComponent tag="h2" className="text-[21px] text-blue-400 mb-5">
              {data.title}
            </HtmlComponent>
          ) : (
            ""
          )}
          {data.intro ? (
            <RichText className="text-editor c-p:mb-2" content={data.intro} />
          ) : (
            ""
          )}
          <div className="py-4">
            <CustomLink
              url={data.custom_link.url}
              new_tab={data.custom_link.new_tab}
            >
              {data.custom_link.text}
            </CustomLink>
          </div>
          {data.item_list ? (
            <ItemList mb="mb-0" item_list={data.item_list} />
          ) : (
            ""
          )}
        </div> 
      </div>
      <div className="order-1 xl:order-2 xl:py-6 h-full w-full">
        <div className="relative h-full w-full">
          {data.picture && (
            <GImage
              data={data.picture}
              className="h-44 xl:absolute xl:inset-0 xl:h-full xl:w-full"
            />
          )}
        </div>
      </div>
    </div>*/}
      </motion.div>
    </div>
  ) : (
    <></>
  );
};

const Hero = ({ data }) => {
  const view = 2;

  return data && view ? (
    <>
      {view === 1 && <View1 data={data} />}
      {view === 2 && <View2 data={data} />}
    </>
  ) : (
    <></>
  );
};

export default Hero;
