import React from "react";
import { graphql } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import Layout from "@components/core/layout";
import Sections from "@components/core/sections";
import Seo from "@components/seo";
import PrivateProfile from "@components/users/profile";
import Logo from "@components/logo";
import StripHTML from "@services/strip-html";

const PageTemplate = ({ location, data, pageContext }) => {
  const { directus } = data;

  return (
    <React.Fragment>
      {/* ------ Référencement ------ */}
      <Seo
        title={
          directus.page.seo && directus.page.seo.meta_title
            ? directus.page.seo.meta_title
            : directus.page.title
        }
        description={
          directus.page.seo && directus.page.seo.meta_description
            ? StripHTML(directus.page.seo.meta_description)
            : directus.page.intro && directus.page.intro.length > 2
            ? StripHTML(directus.page.intro)
            : directus.page.description && directus.page.description.length > 2
            ? StripHTML(directus.page.description)
            : StripHTML(directus.page.title)
        }
        image={
          directus.page.seo &&
          directus.page.seo.share_image &&
          directus.page.seo.share_image.imageFile &&
          directus.page.seo.share_image.imageFile.publicURL
            ? directus.page.seo.share_image.imageFile
            : null
        }
        article={pageContext.article}
      />
      <AnimatePresence>
        <motion.div
          className="w-full h-screen flex items-center justify-center z-50 fixed inset-0 bg-gradient-to-tl from-sky-300 to-sky-200 via-gray-300 filter backdrop-blur"
          initial={{ opacity: 1 }}
          animate={{
            opacity: 0,
            display: "none",
            transition: {
              duration: 0.4,
              ease: "easeInOut",
              delay: 0.4,
              display: {
                delay: 0.8,
                duration: 0,
              },
            },
          }}
          // exit={{
          //   display: "none",
          // }}
        >
          <Logo className="h-44 w-[174px] animate-spin" />
        </motion.div>
        <Layout
          location={location}
          key={location.pathname}
          coordonnees={
            directus.coordonnees[0] ? directus.coordonnees[0].item_list : null
          }
          menusLinks={pageContext.menus}
        >
          {/* ------ Affichages d'une page du menu (plusieurs affichages possibles) ------ */}
          <div
            id={`page-${pageContext.slug ? pageContext.slug : "home"}`}
            className="page-container min-h-80vh"
          >
            {/* Display content sections */}
            {directus.page.sections && directus.page.sections.length > 0 ? (
              <Sections sections={directus.page.sections} />
            ) : (
              ""
            )}

            {pageContext.slug === "profile" && <PrivateProfile />}
          </div>
        </Layout>
      </AnimatePresence>
    </React.Fragment>
  );
};

export default PageTemplate;

export const query = graphql`
  query ($id: ID!, $color: String) {
    directus: allDirectus {
      menus: pages(sort: "sort") {
        id
        title
        slug
        menu_status
      }
      coordonnees: pages(filter: { title: { _eq: "Contact" } }) {
        id
      }
      page: pages_by_id(id: $id) {
        id
        title
        slug
        seo {
          id
          meta_title
          meta_description
          share_image {
            id
            title
            description
            style
            img_style
            object_position
            background_color
            alt
            imageFile {
              id
              childImageSharp {
                id
                gatsbyImageData(
                  quality: 80
                  layout: CONSTRAINED
                  placeholder: TRACED_SVG
                  tracedSVGOptions: { color: $color }
                )
              }
            }
          }
        }
        sections(sort: "sort") {
          id
          collection
          item {
            ... on DirectusData_categories {
              ...categoriesFields
            }
            ... on DirectusData_articles {
              ...articlesFields
            }
            ... on DirectusData_sections_banner {
              ...sectionsBannerFields
            }
            ... on DirectusData_sections_contact_form {
              ...sectionsContactForm
            }
            ... on DirectusData_sections_faq {
              ...sectionsFaq
            }
            ... on DirectusData_sections_feature_columns {
              ...sectionsFeatureColumns
            }
            ... on DirectusData_sections_feature_group {
              ...sectionsFeatureGroup
            }
            ... on DirectusData_sections_feature_rows {
              ...sectionsFeatureRows
            }
            ... on DirectusData_sections_hero {
              ...sectionsHero
            }
            ... on DirectusData_sections_intro {
              ...sectionsIntro
            }
            ... on DirectusData_sections_feature {
              ...sectionsFeature
            }
          }
        }
      }
    }
  }
`;
