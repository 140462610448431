import React from "react";
import RichText from "@components/rich-text";
import PropTypes from "prop-types";

const ItemList = ({
  item_list,
  icon_class_name,
  item_class_name,
  description_class_name,
}) => {
  return (
    <div className="w-auto inline-block">
      <div className="">
        {item_list && item_list.list
          ? item_list.list.map((item, index) => {
              return (
                <div
                  className={`${
                    item_class_name
                      ? item_class_name
                      : "flex items-center mx-auto py-2"
                  }`}
                  key={index}
                >
                  {item.icone && (
                    <span
                      className={`material-icons-outlined mr-3 ${
                        icon_class_name
                          ? icon_class_name
                          : "text-gray-700 dark:text-gray-300"
                      }`}
                    >
                      {item.icone}
                    </span>
                  )}
                  <RichText
                    className={`${
                      description_class_name
                        ? description_class_name
                        : "text-editor c-p:mb-0"
                    }`}
                    content={item.description}
                  />
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

ItemList.propTypes = {
  item_list: PropTypes.object.isRequired,
  item_class_name: PropTypes.string,
  description_class_name: PropTypes.string,
  icon_class_name: PropTypes.string,
};

export default ItemList;
