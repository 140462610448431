import React from "react";
import { graphql } from "gatsby";
import GImage from "@components/g-image";
import classNames from "classnames";
// import CustomLink from "@components/custom-link";
import HtmlComponent from "@components/html-component";
import ItemList from "@components/item-list";
import RichText from "@components/rich-text";
import Card from "@components/card";

export const sectionsFeatureGroup = graphql`
  fragment sectionsFeatureGroup on DirectusData_sections_feature_group {
    id
    custom_title {
      title
      tag
    }
    category {
      id
      title
      articles {
        id
        articles_id {
          id
          title
          intro
          custom_link {
            new_tab
            url
            text
          }
          picture {
            id
            title
            description
            style
            img_style
            object_position
            background_color
            alt
            imageFile {
              id
              ...imageConstrained
            }
          }
        }
      }
    }
  }
`;

const FeatureGroup = ({ data }) => {
  return (
    <div className="container max-w-screen-2xl mx-auto flex flex-col gap-8 pt-10 sm:pt-20 pb-20 sm:pb-28">
      <Card
        className="py-0"
        color=""
        visible={{
          opacity: 1,
          scale: 1,
          transition: { duration: 1 },
        }}
        hidden={{ opacity: 0, scale: 0.8 }}
      >
        {data.custom_title ? (
          <HtmlComponent
            tag={data.custom_title.tag}
            className="lg:text-3xl inline-flex w-auto px-0 sm:px-8 text-gray-800 font-normal leading-normal"
          >
            {data.custom_title.title}
          </HtmlComponent>
        ) : (
          ""
        )}
      </Card>
      {data && data.category && data.category.articles
        ? data.category.articles.map(({ articles_id: article }, index) => {
            return (
              <div
                key={index}
                className="mx-auto w-full px-4 sm:px-8 max-w-screen-2xl grid grid-cols-1 xl:grid-cols-2 gap-8 z-10"
              >
                {/* Text section */}
                <div
                  className={classNames("mx-auto w-full", {
                    "order-2 xl:order-1": index % 2 === 0,
                    "order-2 xl:order-2": index % 2 === 1,
                  })}
                >
                  <Card
                    visible={{
                      opacity: 1,
                      x: 0,
                      transition: { duration: 0.8 },
                    }}
                    hidden={{ opacity: 0, x: index % 2 === 0 ? -200 : 200 }}
                    className={classNames(
                      "pb-18 h-full from-sky-300 to-sky-200 via-gray-300",
                      {
                        "bg-gradient-to-tl": index % 2 === 0,
                        "bg-gradient-to-tr": index % 2 === 1,
                      }
                    )}
                  >
                    {article.title ? (
                      <HtmlComponent
                        tag="h3"
                        className="text-2xl mt-7 mb-4 text-left leading-snug text-gray-800 dark:text-gray-800"
                      >
                        {article.title}
                      </HtmlComponent>
                    ) : (
                      ""
                    )}
                    {article.intro ? (
                      <RichText
                        className="text-editor dark:c-p:text-gray-800 dark:c-li:text-gray-800 c-blockquote-p:border-gray-700 c-p:mb-2"
                        content={article.intro}
                      />
                    ) : (
                      ""
                    )}
                    {/* {article.custom_link ? (
                    <CustomLink
                      className="btn-sm my-3"
                      url={article.custom_link.url}
                      new_tab={article.custom_link.new_tab}
                    >
                      {article.custom_link.text}
                    </CustomLink>
                  ) : (
                    ""
                  )} */}
                    {article.item_list ? (
                      <ItemList mb="mb-0" item_list={article.item_list} />
                    ) : (
                      ""
                    )}
                  </Card>
                </div>
                {/* Media section */}
                <div
                  className={classNames("", {
                    "order-1 xl:order-2": index % 2 === 0,
                    "order-1 xl:order-1": index % 2 === 1,
                  })}
                >
                  {article.picture && (
                    <Card
                      className="relative h-full w-full"
                      p=""
                      color=""
                      visible={{
                        opacity: 1,
                        x: 0,
                        transition: { duration: 0.8 },
                      }}
                      hidden={{ opacity: 0, x: index % 2 === 0 ? 200 : -200 }}
                    >
                      <a
                        target="_blank"
                        className={`
                        inline-block
                        ${
                          article.custom_link
                            ? "cursor-pointer"
                            : "cursor-default"
                        }
                      `}
                        onClick={(e) =>
                          article.custom_link ? "" : e.preventDefault()
                        }
                        rel="noopener noreferrer"
                        href={
                          article.custom_link && article.custom_link.url
                            ? article.custom_link.url
                            : ""
                        }
                      >
                        <GImage
                          data={article.picture}
                          className="h-44 xl:!absolute xl:inset-0 xl:h-full xl:w-full rounded-3xl"
                        />
                      </a>
                    </Card>
                  )}
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default FeatureGroup;
