import React from "react";
import { graphql } from "gatsby";
import GImage from "@components/g-image";
import FMDisclosure from "@components/fm-disclosure";
import HtmlComponent from "@components/html-component";
import Card from "@components/card";

export const sectionsFaq = graphql`
  fragment sectionsFaq on DirectusData_sections_faq {
    id
    custom_title {
      title
      tag
    }
    item_list {
      title
      list
    }
    picture {
      id
      title
      description
      style
      img_style
      object_position
      background_color
      alt
      imageFile {
        id
        ...imageConstrained
      }
    }
  }
`;

const Faq = ({ data }) => {
  if (!data) return false;
  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <div className="pb-10 lg:pb-20 pt-20 sm:pt-32 grid gap-8 xl:grid-cols-12 grid-cols-1 px-4 sm:px-8 max-w-screen-2xl mx-auto">
      <div className="relative xl:col-span-5">
        {data.picture && (
          <Card
            p=""
            color=""
            visible={{
              opacity: 1,
              scale: 1,
              transition: { duration: 1 },
            }}
            hidden={{ opacity: 0, scale: 0.8 }}
            className="h-72 xl:h-full"
          >
            <GImage data={data.picture} className="h-full w-full" />
          </Card>
        )}
      </div>
      <Card
        p=""
        color=""
        className="xl:col-span-7"
        // p="sm:px-9 sm:py-9"
        // color="sm:bg-white sm:dark:bg-gray-950"
        // className="xl:col-span-7"
      >
        {/* <HtmlComponent tag="h3" className="text-[21px] text-gray-800 mb-9">
            {data.title}
          </HtmlComponent> */}
        {data.custom_title ? (
          <Card
            p=""
            color=""
            visible={{
              opacity: 1,
              scale: 1,
              transition: { duration: 1 },
            }}
            hidden={{ opacity: 0, scale: 0.8 }}
          >
            <HtmlComponent
              tag={data.custom_title.tag}
              className="lg:text-3xl px-2 text-gray-800 font-normal mb-9 max-w-2xl leading-normal"
            >
              {data.custom_title.title}
            </HtmlComponent>
          </Card>
        ) : (
          ""
        )}
        <FMDisclosure item_list={data.item_list} />
      </Card>
    </div>
  );
};

export default Faq;
