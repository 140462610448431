import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const CustomLink = ({ title, url, className, new_tab, children }) => {
  const isInternalLink = url.startsWith("/");

  // For internal links, use the Next.js Link component
  if (isInternalLink) {
    return (
      <Link
        title={title}
        className={className ? className : "a-link"}
        to={url}
        as={url}
      >
        {children}
      </Link>
    );
  }

  // Plain <a> tags for external links
  if (new_tab) {
    return (
      <a title={title} href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return (
    <a title={title} href={url} target="_self">
      {children}
    </a>
  );
};

CustomLink.propTypes = {
  url: PropTypes.string.isRequired,
  new_tab: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default CustomLink;
