import React from "react";
import { motion } from "framer-motion";

const DeskSvg = (props) => {
  const svgVariants = {};
  const codingLine = {
    hidden: { visibility: "hidden", pathLength: 0 },
    visible: {
      pathLength: 1,
      visibility: "visible",
    },
  };

  return (
    <div className="w-full relative">
      <object
        {...props}
        type="application/svg+xml"
        alt="Création de site web responsive design sur-mesure"
        title=""
      >
        <motion.svg
          viewBox="0 0 370 247"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          initial="hidden"
          animate="visible"
          variants={svgVariants}
          className={props.className}
        >
          <path d="M333.916 30L102 30" stroke="#020202" strokeWidth="17" />
          <mask id="path-2-inside-1" fill="white">
            <rect x="99" y="19" width="237" height="147" rx="2" />
          </mask>
          <rect
            x="99"
            y="19"
            width="237"
            height="147"
            rx="2"
            stroke="black"
            strokeWidth="8"
            mask="url(#path-2-inside-1)"
          />
          <rect
            x="103"
            y="23"
            width="229"
            height="139"
            fill="#74ACE3"
            fillOpacity="0.5"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 4.5,
                ease: "easeInOut",
              },
            }}
            d="M275 49L115 49"
            stroke="#E7E7E7"
            strokeWidth="11"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 4.5,
                ease: "easeInOut",
              },
            }}
            d="M275 67L115 67"
            stroke="#E7E7E7"
            strokeWidth="11"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 4.5,
                ease: "easeInOut",
              },
            }}
            d="M275 86L115 86"
            stroke="#E7E7E7"
            strokeWidth="11"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 4.5,
                ease: "easeInOut",
              },
            }}
            d="M275 104L115 104"
            stroke="#E7E7E7"
            strokeWidth="11"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 4.5,
                ease: "easeInOut",
              },
            }}
            d="M275 124L114 124"
            stroke="#E7E7E7"
            strokeWidth="11"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 4.5,
                ease: "easeInOut",
              },
            }}
            d="M282 72H321"
            stroke="#E7E7E7"
            strokeWidth="57"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 4.5,
                ease: "easeInOut",
              },
            }}
            d="M282 131H321"
            stroke="#E7E7E7"
            strokeWidth="45"
            strokeLinejoin="round"
          />
          <path
            d="M112 30H147.213"
            stroke="#BDBDBD"
            strokeWidth="9"
            strokeLinecap="round"
          />
          <path
            d="M159.148 30H194.361"
            stroke="#E7E7E7"
            strokeWidth="8"
            strokeLinecap="round"
          />
          <rect
            x="38"
            y="119"
            width="168"
            height="101"
            fill="#333333"
            fillOpacity="0.9"
          />
          <mask id="path-14-inside-2" fill="white">
            <rect x="34" y="114.57" width="175.848" height="112.135" rx="4" />
          </mask>
          <rect
            x="34"
            y="114.57"
            width="175.848"
            height="112.135"
            rx="4"
            stroke="black"
            strokeWidth="10"
            mask="url(#path-14-inside-2)"
          />
          <rect x="39" y="219" width="166" height="3" fill="black" />
          <path
            d="M34 224.475C34 223.595 34.7131 222.882 35.5928 222.882H112.043C112.651 222.882 113.225 223.158 113.605 223.633L113.678 223.724C114.058 224.199 114.632 224.475 115.24 224.475H121.924H128.608C129.216 224.475 129.791 224.199 130.17 223.724L130.243 223.633C130.623 223.158 131.198 222.882 131.805 222.882H208.255C209.135 222.882 209.848 223.595 209.848 224.475C209.848 227.114 207.709 229.253 205.07 229.253H38.7785C36.1394 229.253 34 227.114 34 224.475Z"
            fill="#6C6D72"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 1.5,
                ease: "easeInOut",
              },
            }}
            d="M58.211 136.232H156.329"
            stroke="#789ACD"
            strokeWidth="8"
            strokeLinecap="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 2,
                ease: "easeInOut",
              },
            }}
            d="M84.9705 152.798H118.101"
            stroke="#C6DD38"
            strokeWidth="8"
            strokeLinecap="round"
          />

          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 2.5,
                ease: "easeInOut",
              },
            }}
            d="M137.215 152.798H185.637"
            stroke="#44ED99"
            strokeWidth="8"
            strokeLinecap="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 3,
                ease: "easeInOut",
              },
            }}
            d="M83.6962 170.637L160.152 170.637"
            stroke="#CF5555"
            strokeWidth="8"
            strokeLinecap="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 3.5,
                ease: "easeInOut",
              },
            }}
            d="M98.9873 187.203L175.443 187.203"
            stroke="#789ACD"
            strokeWidth="8"
            strokeLinecap="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 4,
                ease: "easeInOut",
              },
            }}
            d="M83.6962 203.768L160.152 203.768"
            stroke="#44ED99"
            strokeWidth="8"
            strokeLinecap="round"
          />
          <path d="M287.312 140.591H229.1" stroke="black" strokeWidth="11" />
          <rect
            x="229.312"
            y="225.591"
            width="92"
            height="62"
            transform="rotate(-90 229.312 227.591)"
            fill="#365b81"
            fillOpacity="1"
          />
          <rect
            x="227"
            y="229.844"
            width="96.8439"
            height="62.4388"
            rx="2"
            transform="rotate(-90 227 229.844)"
          />
          <rect
            x="227"
            y="229.844"
            width="96.8439"
            height="62.4388"
            rx="2"
            transform="rotate(-90 227 229.844)"
            stroke="black"
            strokeWidth="4"
            mask="url(#path-25-inside-3)"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 5,
                ease: "easeInOut",
              },
            }}
            d="M260.131 154.662H232.097"
            stroke="#E7E7E7"
            strokeWidth="6"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 5,
                ease: "easeInOut",
              },
            }}
            d="M260.131 166.131L232.097 166.131"
            stroke="#E7E7E7"
            strokeWidth="6"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 5,
                ease: "easeInOut",
              },
            }}
            d="M283.068 177.599H232.097"
            stroke="#E7E7E7"
            strokeWidth="6"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 5,
                ease: "easeInOut",
              },
            }}
            d="M283.068 189.068L232.097 189.068"
            stroke="#E7E7E7"
            strokeWidth="6"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 5,
                ease: "easeInOut",
              },
            }}
            d="M283.068 208.181L232.097 208.181"
            stroke="#E7E7E7"
            strokeWidth="21"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 5,
                ease: "easeInOut",
              },
            }}
            d="M262.679 160.397H283.577"
            stroke="#E7E7E7"
            strokeWidth="18"
            strokeLinejoin="round"
          />
          <path
            d="M234.312 140.591H247.055"
            stroke="#BDBDBD"
            strokeWidth="5"
            strokeLinecap="round"
          />
          <path
            d="M254.312 140.591H264.506"
            stroke="#E7E7E7"
            strokeWidth="5"
            strokeLinecap="round"
          />
          <rect
            x="308"
            y="228"
            width="52"
            height="26"
            transform="rotate(-90 308 228)"
            fill="#E37474"
            fillOpacity="0.5"
          />
          <rect
            x="307.692"
            y="228.801"
            width="53.3411"
            height="27.308"
            rx="1"
            transform="rotate(-90 307.692 228.801)"
            stroke="black"
            strokeWidth="2"
          />
          <circle cx="321.346" cy="176.371" r="1.91139" fill="black" />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 5.5,
                ease: "easeInOut",
              },
            }}
            d="M310.515 213.962H332.177"
            stroke="#E7E7E7"
            strokeWidth="16"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 5.5,
                ease: "easeInOut",
              },
            }}
            d="M310.515 198.671H332.177"
            stroke="#E7E7E7"
            strokeWidth="7"
            strokeLinejoin="round"
          />
          <motion.path
            initial="hidden"
            animate="visible"
            variants={codingLine}
            transition={{
              default: {
                duration: 0.5,
                delay: 5.5,
                ease: "easeInOut",
              },
            }}
            d="M310.515 188.477H332.177"
            stroke="#E7E7E7"
            strokeWidth="7"
            strokeLinejoin="round"
          />
        </motion.svg>
      </object>
    </div>
  );
};

export default DeskSvg;
