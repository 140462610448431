import React from "react";
import PropTypes from "prop-types";
/* ---- Components ---- */
import Articles from "@components/core/sections/articles";
import Categories from "@components/core/sections/categories";
import Banner from "@components/core/sections/banner";
import ContactForm from "@components/core/sections/contact-form";
import Faq from "@components/core/sections/faq";
import FeatureColumns from "@components/core/sections/feature-columns";
import FeatureGroup from "@components/core/sections/feature-group";
import FeatureRows from "@components/core/sections/feature-rows";
import Hero from "@components/core/sections/hero";
import Intro from "@components/core/sections/intro";
import Feature from "@components/core/sections/feature";

// Map Directus sections to section components
const sectionComponents = {
  articles: Articles,
  categories: Categories,
  sections_banner: Banner,
  sections_contact_form: ContactForm,
  sections_faq: Faq,
  sections_feature_columns: FeatureColumns,
  sections_feature_group: FeatureGroup,
  sections_feature_rows: FeatureRows,
  sections_feature: Feature,
  sections_hero: Hero,
  sections_intro: Intro,
};

// Display a section individually
const Section = ({ sectionData }) => {
  // Prepare the component
  const SectionComponent = sectionComponents[sectionData.collection];

  if (!SectionComponent) {
    return null;
  }

  // Display the section
  return (
    <section
      id={`${sectionData.collection}${sectionData.id}`}
      className={`${sectionData.collection}`}
    >
      <SectionComponent data={sectionData.item} />
    </section>
  );
};

// Display the list of sections
const Sections = ({ sections }) => {
  return (
    <div className="sections-fragment">
      {sections
        ? sections.map((section) => (
            <Section
              sectionData={section}
              key={`${section.collection}${section.id}`}
            />
          ))
        : ""}
    </div>
  );
};

Sections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sections;
