import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useLocation } from "@reach/router";
import parse from "html-react-parser";
// import { Tab } from "@headlessui/react";
import {
  UserEmailForm,
  UserIdentityForm,
  UserPasswordForm,
  UserBirthdayForm,
  UserPhoneForm,
  UserLocationForm,
  UserDescriptionForm,
  UserAvailabilityForm,
} from "@components/forms/user-form";
import useContent from "@services/provider/use-content";
import useAuth from "@services/provider/use-auth";
import { nl2br } from "@services/javascript-function";
import Emoji from "@components/emoji";

const tradUserEdit = {
  Student: "Étudiant",
  Teacher: "Professeur",
  description: "Description",
  identity: "Identité",
  password: "Mot de passe",
  birthday: "Anniversaire",
  email: "Adresse email",
  phone: "Téléphone",
  location: "Localisation",
  monsieur: "M.",
  madame: "Mme",
  nc: "",
};

const modNames = [
  "description",
  "identity",
  "password",
  "birthday",
  "email",
  "phone",
  "location",
];

const PrivateProfile = () => {
  const { isSignedIn, currentUser } = useAuth();
  const { setModal, modalState, roles, setFixedButton } = useContent();

  const [mod, setMod] = useState(null);
  const [roleName, setRoleName] = useState("");

  const location = useLocation();

  const NavigateMod = () => {
    return (
      <React.Fragment>
        {modNames[modNames.indexOf(mod) - 1] ? (
          <button
            onClick={() => {
              setModal({ open: false, name: null });
              setMod(modNames[modNames.indexOf(mod) - 1]);
            }}
            className="absolute flex items-center justify-center left-0 top-0 bg-sky-400 dark:bg-gray-950 h-8 w-8 rounded-tl-sm rounded-br-sm"
          >
            <span className="material-icons-outlined text-[35px] text-white">
              chevron_left
            </span>
          </button>
        ) : (
          <span className="absolute flex items-center justify-center left-0 top-0 bg-gray-400 h-8 w-8 rounded-tl-sm rounded-br-sm material-icons-outlined text-[22px] text-white">
            block
          </span>
        )}
        {modNames[modNames.indexOf(mod) + 1] ? (
          <button
            onClick={() => {
              setModal({ open: false, name: null });
              setMod(modNames[modNames.indexOf(mod) + 1]);
            }}
            className="absolute flex items-center justify-center right-0 top-0 bg-sky-400 dark:bg-gray-950 h-8 w-8 rounded-tr-sm rounded-bl-sm"
          >
            <span className="material-icons-outlined text-[35px] text-white">
              chevron_right
            </span>
          </button>
        ) : (
          <span className="absolute flex items-center justify-center right-0 top-0 bg-gray-400 h-8 w-8 rounded-tr-sm rounded-bl-sm material-icons-outlined text-[22px] text-white">
            block
          </span>
        )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    const currentRole =
      roles &&
      currentUser &&
      roles.filter((role) => role.id === currentUser.role);
    if (currentRole && currentRole[0] && currentRole[0].name && !roleName) {
      setRoleName(currentRole[0].name);
    }

    if (mod && !modalState.open) {
      setMod(null);
      setModal({
        params: {
          backdropDismiss: false,
          handleClose: () => {
            setModal({
              open: false,
              name: null,
            });
            setFixedButton({
              name: null,
              params: null,
              content: null,
            });
          },
          className:
            "m-5 py-5 px-8 sm:py-5 sm:px-10 bg-white dark:bg-gray-900 rounded-sm shadow-lg overflow-x-hidden",
        },
        name: `edit-${mod}`,
        open: true,
        content: (
          <div className="pt-4 pb-4">
            <NavigateMod />

            <h3 className="my-4">{tradUserEdit[mod]}</h3>
            <hr className="w-full m-0 p-0 pb-2" />

            {mod === "description" && (
              <UserDescriptionForm
                title={currentUser.title}
                description={currentUser.description}
              />
            )}
            {mod === "identity" && (
              <UserIdentityForm
                genre={currentUser.genre}
                first_name={currentUser.first_name}
                last_name={currentUser.last_name}
              />
            )}
            {mod === "password" && <UserPasswordForm />}
            {mod === "birthday" && (
              <UserBirthdayForm birthday={currentUser.birthday} />
            )}

            {mod === "email" && <UserEmailForm email={currentUser.email} />}
            {mod === "phone" && <UserPhoneForm phone={currentUser.phone} />}
            {mod === "location" && (
              <UserLocationForm location={currentUser.location} />
            )}

            {/* TEACHER */}

            {/* STUDENT */}
          </div>
        ),
      });
    }
  }, [
    mod,
    currentUser,
    setModal,
    modalState,
    setFixedButton,
    roles,
    roleName,
    location,
  ]);

  return isSignedIn ? (
    <div className="relative container max-w-screen-xl mx-auto px-0 lg:px-5 pt-4 min-h-screen">
      {currentUser ? (
        <React.Fragment>
          <div className="px-3 sm:px-0">
            <div className="relative flex flex-wrap sm:flex-nowrap gap-2 sm:gap-12 my-10 mb-12 px-3 sm:px-0">
              <div className="border-2 border-gray-200 rounded-sm bg-white dark:bg-gray-800 w-full sm:w-auto">
                <div className="flex justify-center items-center pt-3 pb-4 px-8 h-full w-full">
                  {currentUser.availability && (
                    <UserAvailabilityForm
                      availability={currentUser.availability}
                    />
                  )}
                </div>
              </div>
              <div className="border-2 border-gray-200 rounded-sm bg-white dark:bg-gray-800 w-full">
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => setMod("description")}
                  onClick={() => setMod("description")}
                  className="py-4 px-8 grid grid-cols-1 h-full gap-4 hover:cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  <h3 className="flex items-center">
                    {roleName && (
                      <Emoji
                        icon={roleName === "Student" ? "👨‍🎓" : "👨‍🏫"}
                        label={tradUserEdit[roleName]}
                        className="mr-3 text-2xl5"
                      />
                    )}
                    {currentUser.title
                      ? currentUser.title
                      : roleName &&
                        tradUserEdit[roleName] + " " + currentUser.first_name}
                  </h3>
                  {currentUser.description && (
                    <div className="py-2 px-4">
                      <p className="italic font-normal text-gray-700">
                        {parse(nl2br(currentUser.description, true, true))}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-12 my-10 mb-12 px-3 sm:px-0">
            <div className="border-2 border-gray-200 rounded-sm bg-white dark:bg-gray-800">
              <div className="pt-8 pb-4 px-8">
                <h4 className="text-left">Informations générales</h4>
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => setMod("identity")}
                onClick={() => setMod("identity")}
                className="grid grid-cols-2 gap-4 pt-5 px-8 hover:cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <div className="flex items-center w-full col-span-2 sm:col-span-1">
                  <p className="uppercase text-gray-600 text-sm tracking-wider">
                    Identité
                  </p>
                </div>
                <div className="flex items-center w-full col-span-2 sm:col-span-1">
                  <p className="text-sm sm:text-base">
                    {currentUser.first_name || currentUser.last_name ? (
                      `${
                        currentUser.genre
                          ? tradUserEdit[currentUser.genre] + " "
                          : ""
                      }
                ${currentUser.first_name ? currentUser.first_name + " " : ""}
                ${currentUser.last_name ? currentUser.last_name : ""}`
                    ) : (
                      <span className="material-icons">more_horiz</span>
                    )}
                  </p>
                </div>
                <hr className="col-span-2 w-full m-0 p-0" />
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => setMod("password")}
                onClick={() => setMod("password")}
                className="grid grid-cols-2 gap-4 pt-5 px-8 hover:cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <div className="flex items-center">
                  <p className="uppercase text-gray-600 text-sm tracking-wider">
                    Mot de passe
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="text-lg sm:text-xl">
                    {currentUser.password ? (
                      <span className="material-icons text-inherit mt-1">
                        key
                      </span>
                    ) : (
                      <span className="material-icons text-inherit mt-1">
                        key
                      </span>
                    )}
                  </p>
                </div>
                <hr className="col-span-2 w-full m-0 p-0" />
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => setMod("birthday")}
                onClick={() => setMod("birthday")}
                className="grid grid-cols-2 gap-4 pt-5 px-8 hover:cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <div className="flex items-center">
                  <p className="uppercase text-gray-600 text-sm tracking-wider">
                    Anniversaire
                  </p>
                </div>
                <div>
                  <p className="text-xl">
                    {currentUser.birthday ? (
                      format(new Date(currentUser.birthday), "dd/MM/yyyy", {
                        locale: fr,
                      })
                    ) : (
                      <span className="material-icons">more_horiz</span>
                    )}
                  </p>
                </div>
                <hr className="col-span-2 w-full m-0 p-0 border-transparent" />
              </div>
            </div>
            <div className="border-2 border-gray-200 rounded-sm bg-white dark:bg-gray-800">
              <div className="pt-8 pb-4 px-8">
                <h4 className="text-left">Coordonnées</h4>
              </div>
              <div
                role="button"
                tabIndex={0}
                className="grid grid-cols-2 gap-4 pt-5 px-8 cursor-default"
              >
                <div className="flex h-full items-center w-full col-span-2 sm:col-span-1">
                  <p className="uppercase text-gray-600 text-sm tracking-wider">
                    Email
                  </p>
                </div>
                <div className="flex h-full items-center w-full col-span-2 sm:col-span-1">
                  <p className="text-sm sm:text-base">
                    {currentUser.email ? (
                      currentUser.email
                    ) : (
                      <span className="material-icons">more_horiz</span>
                    )}
                  </p>
                </div>
                <hr className="col-span-2 w-full m-0 p-0" />
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => setMod("phone")}
                onClick={() => setMod("phone")}
                className="grid grid-cols-2 gap-4 pt-5 px-8 hover:cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <div className="flex h-full items-center w-full col-span-2 sm:col-span-1">
                  <p className="uppercase text-gray-600 text-sm tracking-wider">
                    Téléphone
                  </p>
                </div>
                <div className="flex h-full items-center w-full col-span-2 sm:col-span-1">
                  <p className="text-base sm:text-xl">
                    {currentUser.phone ? (
                      currentUser.phone
                    ) : (
                      <span className="material-icons">more_horiz</span>
                    )}
                  </p>
                </div>
                <hr className="col-span-2 w-full m-0 p-0" />
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => setMod("location")}
                onClick={() => setMod("location")}
                className="grid grid-cols-2 gap-4 pt-5 px-8 hover:cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <div className="flex h-full items-center w-full col-span-2 sm:col-span-1">
                  <p className="uppercase text-gray-600 text-sm tracking-wider">
                    Localisation
                  </p>
                </div>
                <div className="flex h-full items-center w-full col-span-2 sm:col-span-1">
                  <p className="text-sm sm:text-base">
                    {currentUser.location ? (
                      currentUser.location
                    ) : (
                      <span className="material-icons">more_horiz</span>
                    )}
                  </p>
                </div>
                <hr className="col-span-2 w-full m-0 p-0 border-transparent" />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="h-full w-full flex justify-center items-center py-24 px-3">
          <span className="material-icons text-[100px] animate-spin text-gray-800 dark:text-gray-100">
            autorenew
          </span>
        </div>
      )}

      {/* Accordion menu */}
      {/* <div className="w-full px-2 py-16 sm:px-0">
        <Tab.Group>
          <Tab.List className="flex p-1 space-x-1 bg-gray-900/20 dark:bg-gray-200/20 rounded-xl">
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full uppercase py-2.5 text-sm leading-5 font-medium text-gray-700 rounded-sm tracking-widest",
                  "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-gray-400 dark:ring-offset-gray-700 ring-white dark:ring-gray-400 ring-opacity-60",
                  selected
                    ? "bg-white dark:bg-gray-950 dark:text-gray-100 shadow"
                    : "text-gray-100 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              accueil
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full uppercase py-2.5 text-sm leading-5 font-medium text-gray-700 rounded-sm tracking-widest",
                  "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-gray-400 dark:ring-offset-gray-700 ring-white dark:ring-gray-400 ring-opacity-60",
                  selected
                    ? "bg-white dark:bg-gray-950 dark:text-gray-100 shadow"
                    : "text-gray-100 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              informations
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel
              className={classNames(
                "bg-white dark:bg-gray-950 rounded-xl p-3",
                "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-gray-200 ring-white ring-opacity-60"
              )}
            >
              <div className="">
                <h4 className="text-center">Fil d'actualité</h4>
                <div className=""></div>
              </div>
            </Tab.Panel>
            <Tab.Panel
              className={classNames(
                "bg-white dark:bg-gray-950 rounded-xl p-3",
                "focus:outline-none focus:ring-2 ring-offset-2 ring-offset-gray-200 ring-white ring-opacity-60"
              )}
            >
              
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div> */}
    </div>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default PrivateProfile;
