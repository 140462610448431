const StripHTML = (originalString) => {
  var strippedString = "";
  if (originalString) {
    strippedString = originalString.replace(/(<([^>]+)>)/gi, "");
    if (strippedString.length > 170) {
      strippedString = strippedString.substring(0, 170) + "...";
    }
  } else {
    strippedString = originalString;
  }
  return strippedString;
};

export default StripHTML;
